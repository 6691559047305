<template>
    <div class="d-flex justify-content-center mb-3">
        <b-spinner class="m-5" label="Spinning" variant="danger"></b-spinner>
    </div>
</template>

<script>
export default {
    name: 'Spinner'
};
</script>
