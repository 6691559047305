<template>
    <div class="section">
        <spinner v-if="refreshData"></spinner>
        <b-container fluid class="px-0" v-if="!refreshData">
            <b-row class="mt-5 mb-5">
                <b-col cols="2" class="mr-3">
                    <img width="220" class="d-block" src="../../../assets/logo-400.png" />
                </b-col>
                <b-col cols="9" class="mt-5 ml-5">
                    <p class="ft-2em">
                        {{ $t('sst.pages.onePreviousBaf.labels.invoiceId', { id: baf.id }) }}
                    </p>
                    <p>
                        {{ $t('sst.pages.onePreviousBaf.labels.invoiceNb', { nb: baf.invoice_ref }) }}
                    </p>
                    <p>
                        {{ $options.filters.formatDate(baf.invoice_date) }}
                    </p>
                    <hr />
                </b-col>
            </b-row>
            <b-row class="mt-5">
                <b-col cols="12">
                    <h1 class="invoiceDetails">
                        {{ $t('sst.pages.onePreviousBaf.labels.invoiceDetails') }}
                    </h1>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-table :items="items" :fields="this.fields" head-variant="dark" show-empty>
                        <template slot="empty">
                            <h4>
                                {{ $t('sst.pages.onePreviousBaf.labels.emptyTable') }}
                            </h4>
                        </template>
                        <template v-slot:custom-foot>
                            <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td class="text-right">
                                    <b>
                                        {{ $t('sst.pages.onePreviousBaf.table.footer.title') }}
                                    </b>
                                </td>
                                <td class="text-center">
                                    <b>
                                        {{ $n(totalAmount, 'currency') }}
                                    </b>
                                </td>
                            </tr>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { BafRepository } from '@/repositories';
import Spinner from '@/components/shared/element/Spinner';

export default {
    name: 'SstBafOnePrevious',
    components: {
        Spinner
    },
    data() {
        return {
            fields: [
                {
                    key: 'id',
                    label: this.$t('sst.pages.onePreviousBaf.table.columns.id'),
                    class: 'text-center'
                },
                {
                    key: 'site.name',
                    label: this.$t('sst.pages.onePreviousBaf.table.columns.site'),
                    class: 'text-center'
                },
                {
                    key: 'metal_rollout_count',
                    label: this.$t('sst.pages.onePreviousBaf.table.columns.metalRollout'),
                    class: 'text-center'
                },
                {
                    key: 'ht_amount',
                    label: this.$t('sst.pages.onePreviousBaf.table.columns.htAmount'),
                    class: 'text-center'
                }
            ],
            items: [],
            refreshData: false,
            baf: null,
            totalAmount: 0
        };
    },
    created() {
        this.data();
    },
    methods: {
        data() {
            this.refreshData = true;
            BafRepository.oneBafById(this.$route.params.id).then(response => {
                this.items = response.data.data.interventions;
                if (this.items) {
                    this.items.map(item => {
                        this.totalAmount += item.ht_amount;
                        item.ht_amount = this.$i18n.n(item.ht_amount, 'currency');
                        return item;
                    });
                }
                this.baf = response.data.data;
                this.refreshData = false;
            });
        }
    }
};
</script>

<style scoped>
.invoiceDetails {
    font-size: 1.7em;
    text-transform: uppercase;
}
.ft-2em {
    font-size: 2em;
}
</style>
